import React, { memo, useCallback, useMemo } from "react";
import FormSelectBox from "./formSelectBox";
import FormTextInput from "./formTextInput";
import FormInputNumber from "./formInputNumber";
import FormTextArea from "./formTextArea";
import FormPasswordInput from "./formPasswordInput";
import FormSwitch from "./formSwitch";
import FormCheckBox from "./formCheckBox";
import FormTimePicker from "./formTimePicker";
import FormDatePicker from "./formDatePicker";
import FormDateAndTimePicker from "./formDateAndTimePicker";
import FormSubmitButton from "./formSubmitButton";
import FormRadioGroup from "./formRadioGroup";
import FormMask from "./formMask";
import FormListInputs from "./formListInputs";

//<FormElement
// formElementName="textInput"
// label="lbl"
// propName="name"
// className=""
// requiredStatus={false}
// placeholder=""
// errorMessage=""
// disabled={false}
// type=""
// formOnBlur={formOnBlur}
// formOnChange={formOnChange}
// autoFocus={false}
// maxLength={50}
// />

//<FormElement
// formElementName="selectBox"
// label="lblName"
// propName="name"
// className=""
// requiredStatus={false}
// placeholder=""
// errorMessage=""
// disabled={false}
// formOnChange={formOnChange}
// selectData={[]}
// otherStatus={false}
// form={form}
// otherPropName=""
// mountDataActive={false}
// yearActice={2017}
// mode="multiple"
// showSearch={true}
// fullDataReturn={false}
// allowClear={false}
///>

//<FormElement
// formElementName="inputNumber"
// label="lblName"
// propName="name1"
// className=""
// requiredStatus={false}
// placeholder=""
// errorMessage=""
// disabled={false}
// formOnBlur={formOnBlur}
// formOnChange={formOnChange}
// max={99999}
// min={0}
// type="password"
//  maxLength={6} // harf sayısı
// />

//<FormElement
// formElementName="textArea"
// label="lblName"
// propName="name4"
// className=""
// requiredStatus={true}
// placeholder=""
// errorMessage=""
// disabled={false}
// formOnBlur={formOnBlur}
// formOnChange={formOnChange}
// />

//<FormElement
// formElementName="passwordInput"
// label="lblPasswordInput"
// propName="name999"
// className=""
// requiredStatus={true}
// placeholder=""
// errorMessage=""
// formOnBlur={formOnBlur}
// formOnChange={formOnChange}
// />

//<FormElement
// formElementName="checkboxSingle"
// label="lblCheckboxSingle"
// propName="name8235"
// className=""
// requiredStatus={true}
// errorMessage=""
// disabled={false}
// formOnChange={formOnChange}
// labelGetwordClose={true}
// contractText={"text"}
// contractActive={false}
// />

//<FormElement
// formElementName="timePicker"
// label="lblTimePicker"
// propName="name1"
// className=""
// requiredStatus={true}
// placeholder=""
// errorMessage=""
// disabled={false}
// formOnChange={formOnChange}
// format={"HH:mm"}
// />

//<FormElement
// formElementName="datePicker"
// label="lblDatePicker"
// propName="name1253334"
// className=""
// requiredStatus={true}
// placeholder=""
// errorMessage=""
// disabled={false}
// formOnChange={formOnChange}
// disablePastTime={false}
// picker="month"
// allowClear={false}
// />

//<FormElement
// formElementName="dateAndTimePicker"
// label="lblDatePicker"
// formOnChange={formOnChange}
// dateData={{
//   propName: "date",
//   className: "",
//   requiredStatus: true,
//   placeholder: "",
//   errorMessage: "",
//   disabled: false,
//   disablePastTime: false,
// }}
// timeData={{
//   propName: "time",
//   className: "",
//   requiredStatus: true,
//   placeholder: "",
//   errorMessage: "",
//   disabled: false,
//   format: "HH:mm",
// }}
// />

//<FormElement
// formElementName="radioGroup"
// label="lblRadioGroup"
// formOnChange={formOnChange}
// propName="radiogroup"
// className=""
// requiredStatus={false}
// errorMessage=""
// radioData={[
//   { id: 1, name: "cihan" },
//   { id: 2, name: "kaya" },
// ]}
// form={form}
// otherStatus={false}
// />

// <FormElement
//   formElementName="switch"
//   className=""
//   label="switch"
//   requiredStatus={true}
//   propName="swxitch"
//   errorMessage=""
//   disabled={true}
//   formOnChange={formOnChange}
// />

//<FormElement
//   formElementName="formMask"
//   label="lblFormMask"
//   formOnChange={formOnChange}
//   propName="formMask"
//   className=""
//   requiredStatus={true}
//   errorMessage=""
//   format="phoneNumber"
//   placeholder="0(5__)___-____"
// />

//<FormElement
// formElementName="listInputs"
// label="lblFormListInputs"
// propName="formListGroup"
// formList={formListData}
// buttonName="lblAdd"
// className=""
// deleteItemChange={deleteItemChange}
// />

//<FormElement
// formElementName="button"
//  label="SUBMIT"
//  className=""
//  textColor=""
//  backgroundColor =""
//  loadingActive={true}
//  disabled={true}
//  btnColor="ant-redBtn" / "ant-angularBlue"
//  width={"100%"}
//  labelGetwordClose={false}
//  style={{}}
// />

// const formOnChange = useCallback((value, propName, fullData) => {
//   console.log(value, propName, fullData);
// }, []);

// const formOnBlur = useCallback((value, propName) => {
//   console.log(value, propName);
// }, []);

const FormElement = memo((props) => {
  const {
    formElementName,
    formOnBlur,
    formOnChange,
    label,
    propName,
    className,
    requiredStatus,
    placeholder,
    errorMessage,
    disabled,
    type,
    selectData,
    otherStatus,
    form,
    mountDataActive,
    yearActice,
    mode,
    showSearch,
    fullDataReturn,
    max,
    min,
    format,
    disablePastTime,
    dateData,
    timeData,
    loadingActive,
    backgroundColor,
    textColor,
    contractActive,
    contractText,
    radioData,
    formList,
    buttonName,
    allowClear,
    deleteItemChange,
    otherPropName,
    btnColor,
    width,
    maxLength,
    labelGetwordClose,
    picker,
    style,
    autoFocus,
    secondLabel,
    onScroll
  } = props;

  const onblurControl = useCallback(
    (value, propName) => {
      if (formOnBlur) {
        formOnBlur(value, propName);
      }
    },
    [formOnBlur]
  );

  const onChangeControl = useCallback(
    (value, propName, fullData) => {
      if (formOnChange) {
        formOnChange(value, propName, fullData);
      }
    },
    [formOnChange]
  );

  const deleteItemChangeControl = useCallback(
    (id) => {
      if (deleteItemChange) {
        deleteItemChange(id);
      }
    },
    [deleteItemChange]
  );

  const elementControl = useMemo(() => {
    switch (formElementName) {
      case "textInput":
        return (
          <FormTextInput
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            placeholder={placeholder}
            errorMessage={errorMessage}
            disabled={disabled}
            type={type}
            textInputOnBlur={onblurControl}
            textInputOnChange={onChangeControl}
            autoFocus={autoFocus}
            maxLength={maxLength}
          />
        );

      case "selectBox":
        return (
          <FormSelectBox 
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            placeholder={placeholder}
            errorMessage={errorMessage}
            disabled={disabled}
            selectBoxOnChange={onChangeControl}
            selectData={selectData}
            otherStatus={otherStatus}
            form={form}
            otherPropName={otherPropName}
            mountDataActive={mountDataActive}
            yearActice={yearActice}
            mode={mode}
            showSearch={showSearch}
            fullDataReturn={fullDataReturn}
            allowClear={allowClear}
            onScroll={onScroll}
          />
        );

      case "inputNumber":
        return (
          <FormInputNumber
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            placeholder={placeholder}
            errorMessage={errorMessage}
            disabled={disabled}
            max={max}
            min={min}
            numberInputOnBlur={onblurControl}
            numberInputOnChange={onChangeControl}
            type={type}
            maxLength={maxLength}
          />
        );

      case "textArea":
        return (
          <FormTextArea
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            placeholder={placeholder}
            errorMessage={errorMessage}
            disabled={disabled}
            textAreaOnBlur={onblurControl}
            textAreaOnChange={onChangeControl}
          />
        );

      case "passwordInput":
        return (
          <FormPasswordInput
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            placeholder={placeholder}
            errorMessage={errorMessage}
            passwordInputOnBlur={onblurControl}
            passwordInputOnChange={onChangeControl}
            maxLength={maxLength}
          />
        );

      case "switch":
        return (
          <FormSwitch
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            switchOnChange={onChangeControl}
            disabled={disabled}
            errorMessage={errorMessage}
          />
        );

      case "checkboxSingle":
        return (
          <FormCheckBox
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            checkBoxOnChange={onChangeControl}
            disabled={disabled}
            errorMessage={errorMessage}
            contractActive={contractActive}
            contractText={contractText}
            labelGetwordClose={labelGetwordClose}
            secondLabel={secondLabel}
          />
        );

      case "timePicker":
        return (
          <FormTimePicker
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            timePickerOnChange={onChangeControl}
            disabled={disabled}
            errorMessage={errorMessage}
            placeholder={placeholder}
            format={format}
          />
        );

      case "datePicker":
        return (
          <FormDatePicker
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            datePickerOnChange={onChangeControl}
            disabled={disabled}
            errorMessage={errorMessage}
            placeholder={placeholder}
            disablePastTime={disablePastTime}
            picker={picker}
            allowClear={allowClear}
          />
        );

      case "dateAndTimePicker":
        return (
          <FormDateAndTimePicker
            label={label}
            timeData={timeData}
            dateData={dateData}
            dateAndTimePickerOnChange={onChangeControl}
          />
        );

      case "radioGroup":
        return (
          <FormRadioGroup
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            errorMessage={errorMessage}
            radioData={radioData}
            radioGroupOnChange={onChangeControl}
            form={form}
            otherStatus={otherStatus}
          />
        );

      case "formMask":
        return (
          <FormMask
            label={label}
            propName={propName}
            className={className}
            requiredStatus={requiredStatus}
            errorMessage={errorMessage}
            format={format}
            maskOnChange={onChangeControl}
            placeholder={placeholder}
          />
        );

      case "listInputs":
        return (
          <FormListInputs
            label={label}
            propName={propName}
            className={className}
            formList={formList}
            buttonName={buttonName}
            deleteItemChange={deleteItemChangeControl}
          />
        );

      case "button":
        return (
          <FormSubmitButton 
            label={label}
            className={className}
            loadingActive={loadingActive}
            textColor={textColor}
            backgroundColor={backgroundColor}
            disabled={disabled}
            btnColor={btnColor}
            width={width}
            labelGetwordClose={labelGetwordClose}
            style={style}
          />
        );

      default:
        return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formElementName,
    btnColor,
    formOnBlur,
    formOnChange,
    label,
    otherPropName,
    propName,
    className,
    requiredStatus,
    placeholder,
    errorMessage,
    disabled,
    type,
    formList,
    buttonName,
    selectData,
    otherStatus,
    mountDataActive,
    yearActice,
    mode,
    showSearch,
    fullDataReturn,
    max,
    min,
    format,
    disablePastTime,
    dateData,
    timeData,
    loadingActive,
    backgroundColor,
    textColor,
    contractActive,
    contractText,
    radioData,
    style,
  ]);

  return <React.Fragment>{elementControl}</React.Fragment>;
});

export default FormElement;
